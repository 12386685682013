<template style="">
    <div style="padding-rigth: 10px" class="table-box" @scroll="getScroll" v-if="dados.length > 0">
        <table class="tableTaxonomia">
            <thead>
                <tr class="sticky-top">
                    <th style="min-width:70px !important" class="sticky-1">
                        <span class="table-title">Pais</span>
                    </th>
                    <th style="min-width: 350px !important" :class="scroll">
                        <span class="table-title">Nombre</span>
                    </th>
                    <th class="table-title">
                        <span class="table-title">Promo</span>
                    </th>
                    <th>
                        <span class="table-title">Link</span>
                    </th>
                    <th v-if="!editar">
                        <span class="table-title">Model</span>
                    </th>
                    <th>
                        <span class="table-title">Retail</span>
                    </th>
                    <th v-if="!editar">
                        <span class="table-title">Categoria</span>
                    </th>
                    <th v-if="!editar">
                        <span class="table-title">Brand</span>
                    </th>
                    <th>
                        <span class="table-title">Ultima vez visto</span>
                    </th>
                    <th v-if="!descartar">
                        <span class="table-title">Brand</span>
                    </th>
                    <th v-if="!descartar">
                        <span class="table-title" style="min-width: 350px !important">Nombre</span>
                    </th>
                    <th v-if="!descartar">
                        <span class="table-title">
                            Categoría
                        </span>
                    </th>
                    <th class="table-title" v-if="!descartar">
                        <span class="table-title">
                            Feature 1
                        </span>
                    </th>
                    <th class="table-title" v-if="!descartar">
                        <span class="table-title">
                            Feature 2
                        </span>
                    </th>
                    <th class="table-title" v-if="!descartar">
                        <span class="table-title">
                            Feature 3
                        </span>
                    </th>
                    <th class="table-title" v-if="!descartar">
                        <span class="table-title">
                            Feature 4
                        </span>
                    </th>
                    <th class="table-title" v-if="!descartar">
                        <span class="table-title">
                            Feature 5
                        </span>
                    </th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="item in dados" :key="item.id_" :class="'' + item.class">
                    <td field="pais" class="sticky-1" style="width:70px;">
                        {{ item.country }}
                    </td>
                    <td field="nombre" :class="scroll" style="text-align: left">
                        {{ item.product_name }}
                    </td>
                    <td field="promo" style="text-align: center">
                        {{item.promo || '0,00' }}
                    </td>
                    <td field="link">
                        <a :href="item.link" target="_blank" class="link">Ver en el sitio</a>
                    </td>
                    <td field="model" v-if="!editar">
                        {{ item.model }}
                    </td>
                    <td field="retail">
                        <Chip :label="item.retail" class="chip-custom" style="font-size: 10px"></Chip>
                    </td>
                    <td field="categoria" v-if="!editar">
                        <Chip :label="item.category" class="chip-custom" style="font-size: 10px"></Chip>
                    </td>
                    <td field="brand" v-if="!editar">
                        <Chip :label="item.brand" class="chip-custom" style="font-size: 10px"></Chip>
                    </td>
                    <td field="last_seen">
                        <div v-if="!item.last_seen">
                            NULL
                        </div>
                        <div v-else>
                            {{ item.last_seen }}
                        </div>
                    </td>
                    <td field="brand" v-if="!descartar">
                        <AutoComplete :ref="'brand_' + item.id_" v-model="item.brand_abm"
                            :suggestions="item.options.brand_abm.suggestions" placeholder="Brand"
                            @complete="searchBrand($event, item)" @item-select="selectBrand($event, item)"
                            @clear="clearBrand(item)" :disabled="item.options.brand_abm.disabled" field="name"
                            class="custom-auto-complete auto-complete-no-box" :dropdown="true" />
                    </td>
                    <td field="nombre" v-if="!descartar">
                        <div style="display: flex" class="custom-input">
                            <AutoComplete v-model="item.nombre" :suggestions="item.options.name.suggestions"
                                @complete="searchNames($event, item)" field="newName" class="not-icon"
                                @item-select="selectNames($event, item)" @clear="clearNames(item)" placeholder="Nombre"
                                :disabled="item.options.name.disabled" />
                            <Button label="Agregar" @click="confirmar(item)"
                                :disabled="item.options.btn_agregar.disabled" />
                        </div>
                    </td>
                    <td field="categoria" v-if="!descartar">
                        <AutoComplete v-model="item.categoria_abm" :suggestions="
                            item.options.categoria_abm.suggestions
                        " @complete="searchCategoria($event, item)" @item-select="selectCategoria($event, item)"
                            field="name" placeholder="Categoría" @clear="clearCategoria(item)"
                            class="custom-auto-complete auto-complete-no-box"
                            :disabled="item.options.categoria_abm.disabled" :dropdown="true" />
                    </td>
                    <td field="feature_1" v-if="!descartar">
                        <AutoComplete v-model="item.feature_1" :suggestions="item.options.features.feature_1"
                            @complete="searchFeature($event, item, 'feature_1')" placeholder="Feature 1"
                            :disabled="item.options.features.disabled" field="name"
                            @clear="clearFeature(item, 'feature_1')" class="custom-auto-complete auto-complete-no-box"
                            :dropdown="true" />
                    </td>
                    <td field="feature_2" v-if="!descartar">
                        <AutoComplete v-model="item.feature_2" :suggestions="item.options.features.feature_2"
                            @complete="searchFeature($event, item, 'feature_2')" placeholder="Feature 2"
                            :disabled="item.options.features.disabled" field="name"
                            @clear="clearFeature(item, 'feature_2')" class="custom-auto-complete auto-complete-no-box"
                            :dropdown="true" />
                    </td>
                    <td field="feature_3" v-if="!descartar">
                        <AutoComplete v-model="item.feature_3" :suggestions="item.options.features.feature_3"
                            @complete="searchFeature($event, item, 'feature_3')" placeholder="Feature 3"
                            :disabled="item.options.features.disabled" field="name"
                            @clear="clearFeature(item, 'feature_3')" class="custom-auto-complete auto-complete-no-box"
                            :dropdown="true" />
                    </td>
                    <td field="feature_4" v-if="!descartar">
                        <AutoComplete v-model="item.feature_4" :suggestions="item.options.features.feature_4"
                            @complete="searchFeature($event, item, 'feature_4')" placeholder="Feature 4"
                            :disabled="item.options.features.disabled" field="name"
                            @clear="clearFeature(item, 'feature_4')" class="custom-auto-complete auto-complete-no-box"
                            :dropdown="true" />
                    </td>
                    <td field="feature_5" v-if="!descartar">
                        <AutoComplete v-model="item.feature_5" :suggestions="item.options.features.feature_5"
                            @complete="searchFeature($event, item, 'feature_5')" placeholder="Feature 5"
                            :disabled="item.options.features.disabled" field="name"
                            @clear="clearFeature(item, 'feature_5')" class="custom-auto-complete auto-complete-no-box"
                            :dropdown="true" />
                    </td>
                    <td>
                        <div v-if="!editar && !descartar">
                            <SplitButton :model="item.buttons" :class="'custom-split-button'" label="Taxonomizar"
                                :ref="'btn_' + item.id_" @click="inputButtonTaxonomizar(item)"></SplitButton>
                        </div>

                        <div v-if="editar == true">
                            <div style="display: flex">
                                <ToggleButton @change="onEdit(item)" v-model="item.edit" onLabel="Editar"
                                    offLabel="Editar" class="custom-split-button" />
                                <Button v-if="item.edit" label="Confirmar" :disabled="!item.edit"
                                    class="ml-1 custom-split-button" @click="inputButtonConfirm(item)" />
                                <Button v-if="!item.edit" label="Descartar"
                                    class="ml-1 custom-split-button button-custom-danger"
                                    @click="inputButtonDescartar(item)" />
                            </div>
                        </div>

                        <div v-if="descartar">
                            <Button label="Retroceder" class="ml-1 custom-split-button"
                                @click="inputButtonRetroceder(item)" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
//Components primevue
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Chip from "primevue/chip";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import SplitButton from "primevue/splitbutton";
import ToggleButton from "primevue/togglebutton";

import AutoComplete from "primevue/autocomplete";

//Services
import { ProductsService } from "./../../services/Taxonomia/ProductsService";
import { CategoriaServiceABM } from "./../../services/CategoriaService";
import { FeatureService } from "./../../services/FeatureService";
import { BrandService } from "./../../services/BrandService";
import UsersServices from "../../services/UsersServices";

export default {
    components: {
        DataTable,
        Dropdown,
        InputText,
        Column,
        MultiSelect,
        Chip,
        Toolbar,
        Button,
        Checkbox,
        SplitButton,
        ToggleButton,
        AutoComplete,
    },

    props: ["values", "loading", "editar", "descartar"],

    data() {
        return {
            paisSelectColumn: null,

            //Alert
            time: 2000,

            dados: [],

            scroll: "sticky-2",

            Linha: 0,
        };
    },

    watch: {
        values(newValue, oldValue) {
            console.log(newValue);
            this.dados = newValue;
        },

        dados(newValue, oldValue) {
            console.log(newValue);
            newValue.map((item) => {
                this.Linha += 1;

                if (this.Linha % 2 == 0) {
                    item.class = " linea_gris";
                } else {
                    item.class = " linea_white";
                }
            });
        },
    },

    methods: {
        searchBrand(event, data) {
            var name = event.query;
            data.options.name.disabled = true;

            BrandService.getAutocomplete(name)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    data.options.brand_abm.suggestions = result.data;
                })
                .catch((err) => { });
        },

        selectBrand(event, data) {
            data.options.name.disabled = false;

            if (data.nombre_obj) {
                if (event.value.id == data.nombre_obj.brand_id) {
                    data.options.btn_agregar.disabled = true;
                    data.options.nombre_status = true;
                    data.options.categoria_abm.disabled = true;
                } else {
                    data.nombre = "";
                    data.options.btn_agregar.disabled = true;
                    data.options.nombre_status = false;
                    data.options.categoria_abm.disabled = false;
                }
            } else {
                data.nombre = "";
                data.options.btn_agregar.disabled = true;
                data.options.nombre_status = false;
                data.options.categoria_abm.disabled = false;
            }
        },

        clearBrand(data) {
            data.options.name.disabled = true;
            data.nombre = "";
            data.options.btn_agregar.disabled = true;
            data.options.categoria_abm.disabled = true;
            data.options.features.disabled = true;
            data.nombre_status = false;
        },

        searchNames(event, data) {
            var name = event.query.toUpperCase();
            var brand = data.brand_abm.id;

            data.options.btn_agregar.disabled = true; //Default

            ProductsService.getNames(name, brand)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    if (!result.data.length == 0) {
                        data.options.name.suggestions = result.data.map(el => {
                            const {  name, feature_2, feature_5  } = el;
                            const newName = `${name}${feature_2 ? ' - '+feature_2.name : ''}${feature_5 ? ' - '+feature_5.name : ''}`;
                            return { ...el, newName }
                        });

                        if (name.length >= 3) {
                            var check_list = false;

                            //Verifica se nas suggestions tem um objeto com o name igual ao pesquisado
                            data.options.name.suggestions.map((item) => {
                                if (item.name == name) {
                                    check_list = true;
                                }
                            });

                            if (check_list == true) {
                                data.options.btn_agregar.disabled = true;
                                data.nombre_status = false;
                                data.options.categoria_abm.disabled = true;
                                if (data.categoria_abm.id) {
                                    data.options.features.disabled = true;
                                }
                            } else {
                                data.options.btn_agregar.disabled = false;
                                data.nombre_status = false;
                                console.log(data);
                                data.options.categoria_abm.disabled = false;
                                if (data.categoria_abm.id) {
                                    data.options.features.disabled = false;
                                }
                            }
                        } else {
                            data.options.btn_agregar.disabled = true;
                            data.nombre_status = false;
                            data.options.categoria_abm.disabled = true;
                            if (data.categoria_abm.id) {
                                data.options.features.disabled = true;
                            }
                        }
                    } else {
                        
                        if (name.length >= 3) {
                            data.options.btn_agregar.disabled = false;
                            data.nombre_status = false;
                            data.options.categoria_abm.disabled = false;
                            if (data.categoria_abm.id) {
                                data.options.features.disabled = false;
                            }
                        } else {
                            data.options.btn_agregar.disabled = true;
                            data.nombre_status = false;
                            data.options.categoria_abm.disabled = true;
                            if (data.categoria_abm.id) {
                                data.options.features.disabled = true;
                            }
                        }
                    }

                    console.log(data.nombre_status);
                })
                .catch((err) => { });
        },

        selectNames(event, data) {
            data.nombre = event.value.name;

            data.nombre_obj = event.value;

            data.categoria_abm = event.value.category;

            data.feature_1 = event.value.feature_1;
            data.feature_2 = event.value.feature_2;
            data.feature_3 = event.value.feature_3;
            data.feature_4 = event.value.feature_4;
            data.feature_5 = event.value.feature_5;

            data.nombre_status = true;

            data.options.categoria_abm.disabled = true;
            data.options.features.disabled = true;
            data.options.btn_agregar.disabled = true;
        },

        clearNames(data) {
            data.nombre_status = false; //Default
            data.options.btn_agregar.disabled = true; //Default
        },

        confirmar(data) {
            if (data.nombre_status == false) {
                data.nombre_status = true;
                this.$store.dispatch("alert_success", {
                    message: "Nombre agregado",
                    time: 2000,
                });
            }
        },

        searchCategoria(event, data) {
            var name = event.query;

            data.feature_1 = [];
            data.feature_2 = [];
            data.feature_3 = [];
            data.feature_4 = [];
            data.feature_5 = [];

            data.options.features.disabled = true;

            CategoriaServiceABM.getAutocomplete(name)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    data.options.categoria_abm.suggestions = result.data;
                })
                .catch((err) => { });
        },

        selectCategoria(event, data) {
            if (event.value != null) {
                data.feature_1 = [];
                data.feature_2 = [];
                data.feature_3 = [];
                data.feature_4 = [];
                data.feature_5 = [];

                data.options.features.disabled = false;
            }
        },

        clearCategoria(data) {
            data.categoria_abm = [];
            data.feature_1 = [];
            data.feature_2 = [];
            data.feature_3 = [];
            data.feature_4 = [];
            data.feature_5 = [];
        },

        searchFeature(event, data, field) {
            var name = event.query;

            var pos = field.split("_");
            pos = pos[1];
            console.log(pos);

            var categoria = data.categoria_abm.id;
            console.log(categoria);

            if (categoria != null) {
                FeatureService.getAutocomplete(name, pos, categoria)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        data.options.features[field] = result.data;
                    })
                    .catch((err) => { });
            }
        },

        clearFeature(data, field) { },

        inputButtonTaxonomizar(data) {
            var brand = data.brand_abm.id;
            var categoria = data.categoria_abm.id;
            var nombre_status = data.nombre_status;
            var name = data.nombre;

            console.log(data);

            var id = data.id_;

            var md5 = data.md5_link;

            if (data.feature_1 == null) {
                var feature_1 = null;
            } else {
                var feature_1 = data.feature_1.id;
            }

            if (data.feature_2 == null) {
                var feature_2 = null;
            } else {
                var feature_2 = data.feature_2.id;
            }

            if (data.feature_3 == null) {
                var feature_3 = null;
            } else {
                var feature_3 = data.feature_3.id;
            }

            if (data.feature_4 == null) {
                var feature_4 = null;
            } else {
                var feature_4 = data.feature_4.id;
            }

            if (data.feature_5 == null) {
                var feature_5 = null;
            } else {
                var feature_5 = data.feature_5.id;
            }

            if (brand == null) {
                this.$store.dispatch("alert_error", {
                    message: "Complete el campo de la marca",
                    time: 2000,
                });
            } else if (name == null || name == "") {
                this.$store.dispatch("alert_error", {
                    message: "Se debe completar el campo de nombre.",
                    time: 2000,
                });
            } else if (nombre_status == false || nombre_status != true) {
                this.$store.dispatch("alert_error", {
                    message: "Este nombre no ha sido agregado.",
                    time: 2000,
                });
            } else if (categoria == null) {
                this.$store.dispatch("alert_error", {
                    message: "Seleccione una categoría",
                    time: 2000,
                });
            } else {
                data.class = "taxonomized";

                this.$store.dispatch("alert_success", {
                    message: "Taxonomización guardada",
                    time: 2000,
                });

                ProductsService.postTaxonomizado(
                    name,
                    brand,
                    md5,
                    feature_1,
                    feature_2,
                    feature_3,
                    feature_4,
                    feature_5,
                    categoria
                )
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        if (result.data) {
                            this.dados = this.dados.filter(
                                (item) => item.md5_link != md5
                            );
                            this.$emit("taxonomizado", this.dados);
                            this.parseFocus(id);
                        } else if (result.response.status != 200) {
                        }
                    })
                    .catch((err) => { });
            }
        },

        onEdit(data) {
            console.log(data.edit);
            if (data.edit == true) {
                data.options.brand_abm.disabled = false;
                data.options.categoria_abm.disabled = false;
                data.options.name.disabled = false;
                data.options.features.disabled = false;
                this.parseFocus(data.id_, "inline");
            } else if (data.edit == false) {
                data.options.categoria_abm.disabled = true;
                data.options.name.disabled = true;
                data.options.brand_abm.disabled = true;
                data.options.features.disabled = true;
                data.options.btn_agregar.disabled = true;
            }
        },

        inputButtonDescartar(data) {
            var md5 = data.md5_link;
            this.$emit("descartar");

            var id = data.id_;

            ProductsService.softDelete(md5)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    this.$store.dispatch("alert_success", {
                        message: "Producto descartado",
                        time: 2000,
                    });
                    this.dados = this.dados.filter(
                        (item) => item.md5_link != md5
                    );
                })
                .catch((err) => { });
        },

        inputButtonConfirm(data) {
            console.log(data);
            var brand = data.brand_abm.id;
            var categoria = data.categoria_abm.id;
            var nombre_status = data.nombre_status;
            var name = data.nombre;
            var id = data.id;

            var md5 = data.md5_link;

            if (data.feature_1 == null) {
                var feature_1 = null;
            } else {
                var feature_1 = data.feature_1.id;
            }

            if (data.feature_2 == null) {
                var feature_2 = null;
            } else {
                var feature_2 = data.feature_2.id;
            }

            if (data.feature_3 == null) {
                var feature_3 = null;
            } else {
                var feature_3 = data.feature_3.id;
                console.log(feature_3);
            }

            if (data.feature_4 == null) {
                var feature_4 = null;
            } else {
                var feature_4 = data.feature_4.id;
            }

            if (data.feature_5 == null) {
                var feature_5 = null;
            } else {
                var feature_5 = data.feature_5.id;
            }

            if (brand == null) {
                this.$store.dispatch("alert_error", {
                    message: "Complete el campo de la marca",
                    time: 2000,
                });
            } else if (name == null || name == "") {
                this.$store.dispatch("alert_error", {
                    message: "Se debe completar el campo de nombre.",
                    time: 2000,
                });
            } else if (nombre_status == false || nombre_status != true) {
                this.$store.dispatch("alert_error", {
                    message: "Este nombre no ha sido agregado.",
                    time: 2000,
                });
            } else if (categoria == null) {
                this.$store.dispatch("alert_error", {
                    message: "Seleccione una categoría",
                    time: 2000,
                });
            } else {
                this.$store.dispatch("alert_success", {
                    message: "Taxonomización editada",
                    time: 2000,
                });

                console.log(feature_3);

                ProductsService.postEditTaxonomizado(
                    id,
                    name,
                    brand,
                    md5,
                    feature_1,
                    feature_2,
                    feature_3,
                    feature_4,
                    feature_5,
                    categoria
                )
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        data.edit = false;
                        if (data.edit == false) {
                            data.options.categoria_abm.disabled = true;
                            data.options.name.disabled = true;
                            data.options.brand_abm.disabled = true;
                            data.options.features.disabled = true;
                            data.options.btn_agregar.disabled = true;
                        }
                    })
                    .catch((err) => { });

                var id_ = data.id_;
                this.parseFocus(id_); //Passa o focus para o input brand abaixo
            }
        },

        inputButtonRetroceder(data) {
            var md5 = data.md5_link;

            ProductsService.retrocederDadosByMd5(md5)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    let validValues = [1,0];
                    if (resultValidation != true && !validValues.includes(result.data)) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    this.$emit("retroceder");
                    if (result.data == 1) {
                        this.$store.dispatch("alert_success", {
                            message: "Producto restaurado a Taxonomizado",
                            time: 2000,
                        });
                    } else if (result.data == 0) {
                        this.$store.dispatch("alert_success", {
                            message: "Producto restaurado a Pendiente",
                            time: 2000,
                        });
                    }

                    this.dados = this.dados.filter(
                        (item) => item.md5_link != md5
                    );
                })
                .catch((err) => { });
        },

        getScroll(event) {
            var scrollX = event.srcElement.scrollLeft;
            if (scrollX == 0) {
                this.scroll = "sticky-2";
            } else {
                this.scroll = "sticky-2 sticky-2-moved";
            }
        },

        parseFocus(id, type) {
            try {
                if (type == "inline") {
                    this.$refs["brand_" + id][0].focus();
                } else {
                    this.$refs["brand_" + id][0].focus();
                }
            } catch { }
        },
    },
};
</script>
