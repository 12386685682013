<template>
    <div class="content-query">
        <div class="p-mt-4 container-flex mb-2">
            <Dropdown class="custom-dropdown custom-input-max-min filter p-mr-2" v-model="selectedPais" :filter="true"
                :options="pais" @input="selectPais" @hide="hidePais" optionLabel="name" placeholder="País"
                :disabled="optDisabled.pais" />

            <MultiSelect
                v-model="selectedRetail"
                class="custom-multiselect custom-input-max-min-300 filter p-mr-2"
                :options="retail"
                optionLabel="retail"
                :filter="true"
                placeholder="Retail"
                display="chip"
                @input="selectRetail"
                @hide="hideRetail"
                :disabled="optDisabled.retail"
            />
            <MultiSelect
                v-if="type != 'comparativo' && type != 'historico'"
                v-model="selectedCategoria"
                class="custom-multiselect custom-input-max-min-300 filter p-mr-2"
                :filter="true"
                :options="categoria"
                optionLabel="category"
                placeholder="Categoria"
                display="chip"
                @hide="hideCategoria"
                :disabled="optDisabled.categoria"
            />
            <MultiSelect
                v-if="type != 'comparativo'"
                v-model="selectedBrand"
                class="custom-multiselect custom-input-max-min-300 filter p-mr-2"
                :options="brand"
                optionLabel="brand"
                placeholder="Marcas"
                display="chip"
                :filter="true"
                :disabled="optDisabled.brand"
                @show="showBrand"
                @filter="searchBrand($event)"
            />
            <Button
                v-if="type != 'comparativo' && type != 'historico'"
                class="button-custom p-mr-2 buttons-query custom-button-max-min"
                :label="btnLeftLabel"
                @click="getQuery"
                :disabled="disableObtener"
            ></Button>
            <Button
                class="custom-input-max-min button-custom-green mr-2 custom-button-max-min"
                label="Export"
                v-if="type != 'comparativo' && type != 'historico' && type != 'descartado'"
                :disabled="!disabledExport"
                @click="exportExcel"
            ></Button>
            <Button
                v-if="type != 'comparativo' && type != 'historico'"
                class="button-custom-limpiar buttons-query custom-button-max-min"
                label="LIMPIAR"
                v-on:click="clearCampos"
            ></Button>
        </div>
        <div v-if="type === 'pendiente' || type === 'descartado'">
            <AutoComplete v-model="productName" placeholder="Nombre Producto"
                class="custom-auto-complete p-mr-2 custom-input-max-min" :disabled="disableProductName" :dropdown="true"
                :suggestions="productNameSuggestions" @complete="searchProductName($event, productName)"
                @item-select="selectProductName($event, productName)" @clear="clearName(productName)"></AutoComplete>
            <Calendar v-model="dateDesde"
                class="custom-input-max-min calendar-query custom-auto-complete custom-calendar p-mr-2"
                placeholder="Visto por última vez" :showIcon="true" :disabledDates="invalidDates"
                @date-select="dateDesdeSelect" />

            <Calendar v-model="dateHasta"
                class="custom-input-max-min calendar-query custom-auto-complete custom-calendar p-mr-2"
                placeholder="Fecha hasta" :showIcon="true" :disabledDates="invalidDates" :disabled="datePicker2Disabled"
                @date-select="dateHastaSelect" />
            <Button class="button-custom-limpiar" label="Limpiar Fechas" v-on:click="clearDates"></Button>
        </div>
    </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import Checkbox from "primevue/checkbox";
import Calendar from "primevue/calendar";

//Services
import { AreaService } from "@/services/AreaService";
import { DivisionService } from "@/services/DivisionService";
import { CategoriaServiceABM } from "@/services/CategoriaService";
import { OldBrandService } from "@/services/Taxonomia/OldBrandService";
import { BrandService } from "@/services/BrandService";
import { CountryService } from "@/services/Taxonomia/CountryService";
import { RetailsService } from "@/services/Taxonomia/RetailsService";
import { CategoriaService } from "@/services/Taxonomia/CategoriaService";
import { ProductsService } from "@/services/Taxonomia/ProductsService";

export default {
    components: {
        Dropdown,
        MultiSelect,
        Button,
        AutoComplete,
        Calendar,
        Checkbox,
    },
    data() {
        return {
            disableProductName: false,
            productName: null,
            productNameSuggestions: [],

            dateDesde: null,
            dateHasta: null,
            datePicker2Disabled: true,

            selectedDivision: null,
            selectedArea: null,
            selectedBrand: null,
            selectedCategoria: null,
            selectedPais: null,
            selectedRetail: null,
            selectedCategoria_abm: null,
            selectedBrand_abm: null,

            optDisabled: {
                pais: false,
                retail: true,
                area: false,
                division: true,
                categoria: true,
                brand: true,
            },

            retail: [],
            area: [],
            division: [],
            categoria: [],
            brand: [],
            brand_abm: [],
            pais: [],

            datePicker: null,

            categoria_abm: [],

            selectedPrice: null,

            dateHoy: null,

            priceList: [
                { name: "Precio Lista", id: 1 },
                { name: "Precio Oferta", id: 2 },
                { name: "Precio Extra", id: 3 },
            ],
            invalidDates: null,
        };
    },

    props: [
        "paisList",
        "chipsRetail",
        "chipsArea",
        "chipsDivision",
        "chipsCategoria",
        "chipsBrand",
        "type",
        "chipsCategoria_Abm",
        "btnLeftLabel",
        "disabledExport",
        "disableObtener",
    ],

    created() {
        let today = new Date();

        var dia = 0;
        this.invalidDates = [];

        this.dateHoy = new Date();

        for (var i = 1; dia <= 31; i++) {
            dia += 1;
            let invalidDate = new Date();
            invalidDate.setDate(today.getDate() + dia);
            this.invalidDates.push(invalidDate);
        }

        if (this.type == "historico" || this.type == "comparativo") {
            BrandService.getAll()
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    this.brand_abm = result.data.data;
                })
                .catch((err) => { });
        }

        CountryService.getAll()
            .then((result) => {
                const resultValidation = this.$root.resultValidationRequest(result);

                if (resultValidation != true) {
                    this.$store.dispatch("alert_error", {
                        message: resultValidation,
                        time: this.time,
                    });

                    return;
                }
                this.pais = result.data;
            })
            .catch((err) => { });
    },

    methods: {
        clearBrand() {
            this.productName = "";
        },

        exportExcel() {
            this.$emit("exportExcel")
        },

        searchProductName(event, data) {
            var name = event.query;
            const country = this.selectedPais ? this.selectedPais.country : "";
            const retails = this.selectedRetail
                ? this.selectedRetail.map((el) => el.retail)
                : "";
            const categories = this.selectedCategoria
                ? this.selectedCategoria.map((el) => el.category)
                : "";
            const brands = this.selectedBrand
                ? this.selectedBrand.map((el) => el.brand)
                : "";
            ProductsService.getProductNamesAutocomplete(
                name,
                country,
                retails,
                categories,
                brands
            )
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    result.data = result.data.map((item) => {
                        item = item.toLowerCase();
                        return item;
                    });
                    this.productNameSuggestions = result.data;
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        selectProductName(event, data) {
            // Nothing to do here, by now
        },

        selectPais() {
            RetailsService.getPerCountry(this.selectedPais.country)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    this.retail = result.data;
                })
                .catch((err) => { });
        },

        selectRetail() {
            var country = this.selectedPais.country;

            var retails = this.selectedRetail.map((item) => {
                return item.retail;
            });

            this.selectedCategoria = [];

            CategoriaService.getPerCountry(country, retails)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    result.data = result.data.map((item) => {
                        item.category = item.category.toLowerCase();
                        return item;
                    });
                    this.categoria = result.data;
                })
                .catch((err) => { });
        },

        formatDate(date) {
            if (date) {
                date = date.toLocaleDateString('pt-BR');
                date = date.split("/");
                date = date.reverse();
                date = date[0] + '-' + date[1] + '-' + date[2]
            }
            console.log("Date format", date);
            return date;
        },
        dateHastaSelect(event) {
            var firstDate = this.dateDesde.getUTCDate();
            var lastDate = event.getUTCDate();
            var diff = lastDate - firstDate;
            // By now no restrictions implemented
        },
        dateDesdeSelect(event) {
            this.datePicker2Disabled = false;
        },

        searchArea(event) {
            var name = event.query;
            this.selectedDivision = [];
            this.selectedCategoria_abm = [];

            AreaService.getAutocomplete(name)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    this.area = result.data;
                })
                .catch((err) => { });
        },

        selectArea(event) {
            this.selectedDivision = [];

            this.optDisabled.division = false;

            this.selectedCategoria_abm = [];

            this.optDisabled.categoria = true;
        },

        searchDivision(event) {
            var name = event.query;
            var area_id = this.selectedArea.id;

            if (area_id != null) {
                DivisionService.getAutocomplete(name, area_id)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        this.division = result.data;
                    })
                    .catch((err) => { });
            }
        },

        selectDivision(event) {
            this.selectedCategoria_abm = [];

            this.optDisabled.categoria = false;
        },

        searchCategoria(event) {
            var name = event.query;
            var division_id = this.selectedDivision.id;

            if (division_id != null) {
                CategoriaServiceABM.getAutocomplete(name, division_id)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        result.data = result.data.map((item) => {
                            item.name = item.name.toLowerCase();
                            return item;
                        });
                        this.categoria_abm = result.data;
                    })
                    .catch((err) => { });
            }
        },

        searchBrand(event) {
            if (
                this.selectedPais &&
                this.selectedRetail &&
                this.selectedCategoria
            ) {
                var country = [this.selectedPais.country];
                var retail = [];
                var categoria = [];

                this.selectedRetail.map((item) => {
                    retail.push(item.retail);
                });
                this.selectedCategoria.map((item) => {
                    categoria.push(item.category);
                });

                OldBrandService.getAutoComplete(
                    retail,
                    country,
                    categoria,
                    event.value
                )
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        this.brand = [];
                        this.brand = result.data;

                        this.selectedBrand.map((item) => {
                            var check = false;
                            this.brand.map((brand) => {
                                if (item.brand == brand.brand) {
                                    check = true;
                                }
                            });

                            if (check == false) {
                                this.brand.push(item);
                            }
                        });
                    })
                    .catch((err) => { });
            }
        },

        showBrand() {
            if (
                this.selectedPais &&
                this.selectedRetail &&
                this.selectedCategoria
            ) {
                var country = [this.selectedPais.country];
                var retail = [];
                var categoria = [];

                this.selectedRetail.map((item) => {
                    retail.push(item.retail);
                });
                this.selectedCategoria.map((item) => {
                    categoria.push(item.category);
                });

                OldBrandService.getAutoComplete(retail, country, categoria, "")
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        this.brand = [];
                        result.data = result.data.map((item) => {
                            item.brand = item.brand.toLowerCase();
                            return item;
                        });
                        this.brand = result.data;
                        console.log(this.brand);
                    })
                    .catch((err) => { });
            }
        },

        hidePais() {
            if (this.selectedPais != null) {
                this.clear();
                this.optDisabled.retail = false;
            } else {
                this.optDisabled.retail = true;
            }
        },

        hideRetail() {
            if (this.selectedRetail != null) {
                /* if (this.selectedRetailShow != this.selectedRetail) {
                    this.selectedCategoria = null;
                    this.selectedBrand = null;

                    if (this.selectedRetail.length) {
                        this.categoria = [];
                        this.brand = [];
                    }
                } */

                if (this.type == "comparativo") {
                } else {
                    this.optDisabled.categoria = false;
                }
            } else {
                if (this.type == "comparativo") {
                } else {
                    this.optDisabled.categoria = true;
                }
            }
        },

        hideCategoria() {
            this.optDisabled.brand = false;
            this.selectedBrand = null;
            this.brand = [];
        },

        inputArea() {
            this.selectedDivision = null;
            this.selectedCategoria_abm = null;
            this.optDisabled.division = false;
            this.optDisabled.categoria = true;
            this.$emit("areaInput", this.selectedArea);
        },

        inputDivision() {
            this.selectedCategoria_abm = null;
            this.optDisabled.categoria = false;
            this.$emit("divisionInput", this.selectedDivision);
        },

        selectBrand(event, brands_select) {
            console.log(event);

            brands_select.map((item) => {
                item.checked = true;
            });
        },

        getQuery() {
            var query = {};

            query.country =
                this.selectedPais != null ? this.selectedPais.country : null;
            query.retail =
                this.selectedRetail != null ? this.selectedRetail : null;
            console.log(this.selectedCategoria);
            query.category =
                this.selectedCategoria != null ? this.selectedCategoria : null;
            query.category_abm =
                this.selectedCategoria_abm != null
                    ? this.selectedCategoria_abm
                    : null;

            query.date = this.dateHoy;

            query.brand =
                this.selectedBrand != null ? this.selectedBrand : null;

            query.brand_abm =
                this.selectedBrand_abm != null ? this.selectedBrand_abm : null;

            query.start_date = this.formatDate(this.dateDesde);
            query.end_date = this.formatDate(this.dateHasta);

            query.name = this.productName;

            console.log("Query", query);
            this.$emit("getObtener", query);
        },

        clear() {
            this.selectedRetail = null;

            if (this.type != "comparativo") {
                this.productName = null;
                this.selectedDivision = null;
                this.selectedArea = null;
                this.selectedBrand = null;
                this.selectedCategoria = null;
                this.retail = [];
                this.area = [];
                this.division = [];
                this.categoria = [];

                this.optDisabled = {
                    pais: false,
                    retail: true,
                    area: false,
                    division: true,
                    categoria: true,
                };
            }
        },
        clearDates() {
            this.dateDesde = null;
            this.dateHasta = null;
        },
        clearCampos() {
            this.$emit("ClickClear"); //Function call back
            this.selectedCategoria = null;
            this.selectedRetail = null;
            this.selectedBrand = null;
            this.selectedPais = null;
            this.selectedArea = null;
            this.selectedDivision = null;
            this.dateDesde = null;
            this.dateHasta = null;

            if (this.chipsRetail) {
                this.retail = [];
            }

            if (this.chipsArea) {
                this.area = [];
            }

            if (this.chipsDivision) {
                this.division = [];
            }

            if (this.chipsCategoria) {
                this.categoria = [];
            }

            if (this.chipsBrand) {
                this.brand = [];
            }

            this.optDisabled = {
                pais: false,
                retail: true,
                area: false,
                division: true,
                categoria: true,
            };

            this.disabled = true;
        },

        disabledDaysController() {
            return [1];
        },

        searchBrandABM(event) {
            var name = event.query;

            BrandService.getAutocomplete(name)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    this.brand_abm = result.data;
                })
                .catch((err) => { });
        },
    },
};
</script>
<style lang="css">
    .button-custom:disabled, .button-custom-green:disabled {
        cursor: not-allowed;
    }
</style>