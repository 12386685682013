<template>
  <div>
    <Dialog
      :visible="this.visible"
      :style="{ width: '450px' }"
      :header="header"
      :closable="false"
      :modal="true"
      class="dialog-custom"
    >
      <template #header>
        <span id="pv_id_4_header" class="p-dialog-title">{{ header }}</span>
        <div class="p-dialog-header-icons visible">
          <button
            aria-label="close"
            type="button"
            @click="$emit('close')"
            tabindex="-1"
            class="p-dialog-header-icon p-dialog-header-close p-link"
          >
            <span class="p-dialog-header-close-icon pi pi-times"></span>
          </button>
        </div>
      </template>
      <div class="confirmation-content">
        <div class="row">
          <div class="col p-1 mt-2">
            <div class="p-field p-col-12">
              <div v-bind:class="{ 'mb-2': listDrop }">
                <span class="p-float-label p-input-icon-right">
                  <InputText
                    class="inputtext-custom"
                    id="inputtext-right"
                    type="text"
                    v-model="inputText"
                  />
                  <label for="inputtext-right">{{ inputLabel }}</label>
                </span>
              </div>
              <div class="" v-if="listDrop">
                <Dropdown
                  class="dropdown-custom"
                  v-model="dropSelected"
                  :options="listDrop"
                  optionLabel="name"
                  :placeholder="labelDropdown"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div v-if="id">
          <Button
            :label="btnLeft"
            @click="$emit('close')"
            icon="pi pi-times"
            class="button-custom-invert"
          />
          <Button
            :label="btnRight"
            @click="$emit('save', id)"
            icon="pi pi-check"
            class="button-custom"
          />
        </div>
        <div v-else-if="listDrop">
          <Button
            :label="btnLeft"
            @click="$emit('close')"
            icon="pi pi-times"
            class="button-custom-invert"
          />
          <Button
            :label="btnRight"
            @click="$emit('save', data = {inputText, dropSelected})"
            icon="pi pi-check"
            class="button-custom"
          />
        </div>
        <div v-else>
          <Button
            :label="btnLeft"
            @click="$emit('close')"
            icon="pi pi-times"
            class="button-custom-invert"
          />
          <Button
            :label="btnRight"
            @click="$emit('save', inputText)"
            icon="pi pi-check"
            class="button-custom"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";

export default {
  components: {
    Dialog,
    Button,
    InputText,
    Dropdown,
  },
  data() {
    return {
      inputText: null,
      dropSelected: null,
      //EXEMPLO
      /* cities: [
        { name: "New Yorkasd", code: "NY" },
        { name: "New Yorka", code: "NY" },
        { name: "New Yorkasd", code: "NY" },
      ], */
    };
  },
  props: [
    "header",
    "visible",
    "inputLabel",
    "id",
    "btnLeft",
    "btnRight",
    "inputValue",
    "labelDropdown",
    "listDrop",
  ],

  created() {},
  computed: {
    input() {
      if (this.inputValue != null) {
        this.inputText = this.inputValue;
      }
    },
  },

  methods: {
    hideDialog() {
      this.newAreaDialog = false;
    },
  },
};
</script>
<style lang="">
</style>