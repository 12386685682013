import axios from 'axios';
import env from './../../env';
import XLSX from "xlsx";

const excelService = {

  async getExcel(name) {
    return axios({
      url: env.API + "/" + name + "/import/template",
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Template - ' + name + '.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  },

  async postExcel(name, files) {
    var formData = new FormData();
    console.log(files)
    formData.append('file', files);

    return axios({
      url: env.API + "/" + name + "/import",
      method: 'POST',
      responseType: 'blob',
      headers: { 'content-type': 'multipart/form-data' },
      data: formData,
    });
  },

  writeXLSFile(fileName, sheetName, columns, dataKeys, data) {
    const wb = XLSX.utils.book_new();
    const ws_data = [];
    wb.SheetNames.push(sheetName);
    wb.Props = {
      Title: "Lexart - Tercer OJO",
      Subject: "",
      Author: "Lexart",
      CreatedDate: new Date(),
    };

    ws_data.push(columns);

    data.forEach(document => {
      const line = [];
      dataKeys.forEach(key => line.push(document[key]));

      ws_data.push(line);
    });

    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets[sheetName] = ws;

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i)
        view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    saveAs(
      new Blob([s2ab(wbout)],
      { type: "application/octet-stream" }),
      `${fileName}.xlsx`,
    );
  },
}

export default excelService;
