<template>
    <div>
        <transition name="fade">
            <div
                v-if="exportExcelStatus == true"
                :class="'container_toast_carge ' + toast"
            >
                <Notification :count="total_dados_export" :total="total_dados" />
            </div>
        </transition>

        <div>
            <!-- @_____ == function callback -->
            <Query
                :paisList="paisList"
                :disabledExport="!(!productService.length || isExporting || loading)"
                :disabledObtener="isExporting || loading"
                :chipsArea="ChipsArea"
                :chipsCategoria="ChipsCategoria"
                :chipsDivision="ChipsDivision"
                :chipsRetail="ChipsRetail"
                @InputPais="getPais"
                @ClickClear="clearList"
                @ShowRetail="clickRetail"
                @areaInput="areaInput"
                @divisionInput="divisionInput"
                :chipsBrand="ChipsBrand"
                @getObtener="getObtener"
                @exportExcel="Export"
            ></Query>
        </div>

        <div>
            <Table :values="productService" :editar="true" @descartar="descartarFunc"></Table>
        </div>

        <div class="container-custom" v-if="loading == true">
            <ProgressSpinner />
        </div>

        <div v-if="productService.length > 0">
            <Paginator :total_pages="total_pages" :total_dados="total_dados" :page_init="current_page"
                @handler="getObtener" :props="true"></Paginator>
        </div>

        <span v-show="isExporting" class="exporting-notification" >Exportando datos ...</span>
    </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Chip from "primevue/chip";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import SplitButton from "primevue/splitbutton";
import Table from "./../../components/Tables/Table.vue";
import Query from "../../components/Query/QueryTaxonomizado.vue";
import Notification from "@/components/NotificationDataHandler/Notification.vue";

import ProgressSpinner from "primevue/progressspinner";

import { AreaService } from "../../services/AreaService";
import { DivisionService } from "../../services/DivisionService";
import { CategoriaServiceABM } from "../../services/CategoriaService";
import { BrandService } from "../../services/BrandService";
import excelService from "@/services/Excel/excelService";
import { RetailsService } from "../../services/Taxonomia/RetailsService";
import { CountryService } from "../../services/Taxonomia/CountryService";
import { ProductsService } from "../../services/Taxonomia/ProductsService";

import IndexDB from "@/services/IndexDBService";

import Paginator from "./../../components/Paginator.vue";

export default {
    components: {
        DataTable,
        Dropdown,
        InputText,
        Column,
        MultiSelect,
        Chip,
        Toolbar,
        Button,
        Checkbox,
        SplitButton,
        Table,
        Query,
        ProgressSpinner,
        Paginator,
        Notification,
    },

    data() {
        return {
            // DATA - API
            productService: [],
            loading: false,

            //CHIPS
            //Pais
            paisList: [],
            paisSelect: null,

            //Retail
            ChipsRetail: [],

            //Categoria
            ChipsCategoria: [],

            //Brand
            ChipsBrand: [],

            //Area
            ChipsArea: [],

            //Division
            ChipsDivision: [],

            props: null,
            links: null,
            options: null,

            widthTable: "auto",
            collapse: "table-collapsed",
            spinner: "spinner-not",

            total_dados: 0,
            total_pages: 0,

            total_dados_export: 0,

            props_request: null,

            current_page: 1,
            toast: "",

            exportExcelStatus: false,
            isExporting: false,
            time: 2000,
        };
    },

    created() {

        // IndexDB.createDB(
        //     () => {},
        //     "taxonomizados",
        //     "DBTO",
        //     "categoria, producto, fecha, retail",
        //     ["categoria", "producto", "fecha", "retail"],
        //     false,
        //     { autoIncrement: true, keyPath: "taxo_id" }
        // );

        AreaService.getAll()
            .then((result) => {
                const resultValidation = this.$root.resultValidationRequest(result);

                if (resultValidation != true) {
                    this.$store.dispatch("alert_error", {
                        message: resultValidation,
                        time: this.time,
                    });

                    return;
                }

                this.ChipsArea = result.data.data;
            })
            .catch((err) => { });

        BrandService.getAll()
            .then((result) => {
                const resultValidation = this.$root.resultValidationRequest(result);

                if (resultValidation != true) {
                    this.$store.dispatch("alert_error", {
                        message: resultValidation,
                        time: this.time,
                    });

                    return;
                }

                this.ChipsBrand = result.data.data;
            })
            .catch((err) => { });

        CountryService.getAll()
            .then((result) => {
                const resultValidation = this.$root.resultValidationRequest(result);

                if (resultValidation != true) {
                    this.$store.dispatch("alert_error", {
                        message: resultValidation,
                        time: this.time,
                    });

                    return;
                }

                var id = 0;
                result.data = result.data.map((item) => {
                    id += 1;
                    item.id = id;
                    item.name_backup = item.name;

                    item.name = item.name + " | " + item.country;
                    this.paisList.push(item);
                });
            })
            .catch((err) => {});
    },

    methods: {
        // incrementProductIndexedDB(products) {
        //     return new Promise((resolve) => {
        //         let total_dados_load = 0;

        //         products.map((product) => {
        //             product.categoria = product.category.name;
        //             product.division = product.category.division.name;
        //             product.area = product.category.division.area.name;

        //             product.feature_1 =
        //                 product.feature_1 != null ? product.feature_1.name : null;
        //             product.feature_2 =
        //                 product.feature_2 != null ? product.feature_2.name : null;
        //             product.feature_3 =
        //                 product.feature_3 != null ? product.feature_3.name : null;
        //             product.feature_4 =
        //                 product.feature_4 != null ? product.feature_4.name : null;
        //             product.feature_5 =
        //                 product.feature_5 != null ? product.feature_5.name : null;

        //             product.brand = product.brand.name;
        //             product.producto = product.name;
        //             product.fecha = product.created_at;

        //             product.retail = product.taxonomy_product.retail;
        //             product.enlace = product.taxonomy_product.link;

        //             IndexDB.itemADD(
        //                 product,
        //                 () => {
        //                     total_dados_load++;

        //                     if (total_dados_load === products.length) {
        //                         resolve(total_dados_load);
        //                     }
        //                 },
        //                 "taxonomizados",
        //                 "DBTO"
        //             );
        //         });
        //     });
        // },

        clickRetail() {
            //Alert
            if (this.paisSelect == null) {
                this.$store.dispatch("alert_error", {
                    message: "Seleccione el país primero",
                    time: 2000,
                });
            } else {
                RetailsService.getPerCountry(this.paisSelect.country)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }

                        var names = [];
                        var retailABC = [];

                        this.ChipsRetail = result.data.map((item) => {
                            let retailUC = item.retail.toUpperCase();
                            item.name = retailUC + " | " + this.paisSelect.country;
                            names.push(item.name);
                            item.id = this.paisSelect.country;
                            return item;
                        });

                        names.sort();

                        names.map((name) => {
                            this.ChipsRetail.map((retail) => {
                                if (name == retail.name) {
                                    retailABC.push(retail);
                                }
                            });
                        });

                        this.ChipsRetail = retailABC;
                    })
                    .catch((err) => { });
            }
        },

        areaInput(data, selectDivision) {
            this.ChipsDivision = []; //Clear chips

            DivisionService.getAll() //Request e filtro do id da area selecionada
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }

                    this.ChipsDivision = result.data.data;
                    this.ChipsDivision = this.ChipsDivision.filter(
                        (divisions) => divisions.area_id == data.id
                    );
                    //Alerts
                    if (this.ChipsDivision.length == 0) {
                        this.$store.dispatch("alert_error", {
                            message: "No encontramos divisiones con esta área.",
                            time: 2000,
                        });
                    } else {
                        this.$store.dispatch("alert_success_bottom", {
                            message:
                                "Encontramos un total de " +
                                this.ChipsDivision.length +
                                " divisions.",
                            time: 2000,
                        });
                    }
                })
                .catch((err) => { });
        },

        divisionInput(data) {
            this.ChipsCategoria = []; //Clear chips

            CategoriaServiceABM.getAll() //Request e filtro da division selecionada pelo id
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }

                    this.ChipsCategoria = result.data.data;
                    this.ChipsCategoria = this.ChipsCategoria.filter(
                        (categoria) => categoria.division_id == data.id
                    );
                    //Alerts
                    if (this.ChipsCategoria.length == 0) {
                        this.$store.dispatch("alert_error", {
                            message: "No encontramos categorias con esta divisiones.",
                            time: 2000,
                        });
                    } else {
                        this.$store.dispatch("alert_success_bottom", {
                            message:
                                "Encontramos un total de " +
                                this.ChipsCategoria.length +
                                " categorias.",
                            time: 2000,
                        });
                    }
                })
                .catch((err) => { });
        },

        taxonomizado(id) {
            console.log(id);
        },

        getPais(data) {
            this.paisSelect = data;
        },

        getQuery() {
            var query = [];
            query.push(this.selectedCategoria);
            query.push(this.selectedRetail);
            query.push(this.selectedBrand);
            query.push(this.paisSelect);
            query.push(this.selectedDivision);
            query.push(this.selectedArea);

            console.log(query);
        },

        buildGetParams(data, page = 1) {
            if (!data) {
                data = this.props_request;
            } else {
                this.props_request = data;
            }

            const { country, retail, category, brand, name } = data;
            let retails = [];
            let categoria = [];
            let brands = [];

            if (retail)     retails   = retail.map((item) => item.retail);
            if (category)   categoria = category.map((item) => item.id);
            if (brand)      brands    = data.brand.map((item) =>  item.id);

            if (!retails.length || !categoria.length) {
                const message = retails.length
                    ? 'Category no ha sido llenado'
                    : 'Retail no ha sido llenado';

                this.$store.dispatch("alert_error", {
                    message,
                    time: 3000,
                });

                return false;
            }

            return {
                country: [country],
                retails,
                categoria,
                brands,
                page,
                name,
            };
        },

        getObtener(data, page, returnData = false) {
            const filters = this.buildGetParams(data, page);

            if(!filters) return;

            const { retails, categoria, country, brands, name } = filters;

            if (returnData) {
                return ProductsService.getAllParamsPage(
                    retails,
                    categoria,
                    country,
                    brands,
                    page,
                    name,
                );
            }

            this.productService = [];
            this.loading = true;

            ProductsService.getAllParamsPage(
                retails,
                categoria,
                country,
                brands,
                page,
                name,
            ).then((result) => {
                const resultValidation = this.$root.resultValidationRequest(result);

                if (resultValidation != true) {
                    this.loading = false;
                    this.$store.dispatch("alert_error", {
                        message: resultValidation,
                        time: this.time,
                    });

                    return;
                }

                var id = 0;
                result.data.path = result.data.path + "?page=";

                this.total_dados = result.data.total;
                this.total_pages = result.data.total_pages;
                this.current_page = result.data.current_page;

                result.data.data.map((item) => {
                    id++;

                    item.country = item.taxonomy_product.country;
                    item.last_seen = item.taxonomy_product.last_seen;
                    item.link = item.taxonomy_product.link;
                    item.md5_link = item.taxonomy_product.md5_link;
                    item.model = item.taxonomy_product.model;
                    item.product_name = item.name;

                    item.retail = item.taxonomy_product.retail;

                    item.edit = false;

                    item.brand_abm = item.brand;
                    item.nombre = item.name;
                    item.categoria_abm = item.category;

                    item.id_ = id;

                    item.nombre_status = true;

                    item.class = "";

                    item.options = {
                        brand_abm: { suggestions: [], disabled: false },
                        name: { suggestions: [], disabled: true },
                        categoria_abm: {
                            suggestions: [],
                            disabled: true,
                        },
                        btn_agregar: { disabled: true },
                        features: {
                            disabled: true,
                            feature_1: [],
                            feature_2: [],
                            feature_3: [],
                            feature_4: [],
                            feature_5: [],
                        },
                    };

                    this.productService.push(item); //Passando para props
                });

                this.loading = false;
            }).catch((err) => { });
        },

        clearList() {
            this.ChipsRetail = [];
            this.ChipsCategoria = [];
            this.ChipsDivision = [];

            this.paisSelect = null;
            this.selectedRetail = null;
            this.selectedCategoria = null;

            this.productService = [];

            this.widthTable = "auto";
            this.collapse = "table-collapsed";
            this.spinner = "spinner-not";
        },

        loadings(boolean) {
            if (boolean == true) {
                this.collapse = "table-collapsed"; //Css propriedades
                this.spinner = "spinner-loading"; //Css propriedades
            } else {
                setTimeout(() => {
                    this.collapse = "not-collapsed"; //Css propriedades
                    this.spinner = "spinner-not"; //Css propriedades
                }, 1500);
            }
        },

        descartarFunc() {
            console.log("descartado");
            this.total_dados -= 1;
        },

        async Export() {
            const maxDataAmount = 60000;
            const date = new Date();
            const fileName = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} Taxonomizados`;
            const sheetName = 'historico';
            const columns = [
                "id",
                "taxo_id",
                "Retail",
                "Area",
                "Division",
                "Categoria",
                "Producto",
                "Brand",
                "Feature 1",
                "Feature 2",
                "Feature 3",
                "Feature 4",
                "Feature 5",
                "md5 group",
                "Fecha",
                "Enlace",
            ];
            const dataKeys = [
                "id",
                "taxo_id",
                "retail",
                "area",
                "division",
                "categoria",
                "producto",
                "brand",
                "feature_1",
                "feature_2",
                "feature_3",
                "feature_4",
                "feature_5",
                "md5_group",
                "fecha",
                "enlace",
            ];

            try {
                this.isExporting = true;
                const queryLimit = this.total_dados < maxDataAmount
                    ? this.total_dados
                    : maxDataAmount;

                const filters = this.buildGetParams();
                if(!filters) throw new Error();

                const { retails, categoria, country, brands, page, name } = filters;

                const response = await ProductsService.getAllParamsPage(
                    retails,
                    categoria,
                    country,
                    brands,
                    page,
                    name,
                    queryLimit,
                );
                const productsData = response.data.data.map(el => ({
                    id: el.id,
                    taxo_id: el.taxo_id,
                    retail: el.taxonomy_product.retail,
                    area: el.category.division.area.name,
                    division: el.category.division.name,
                    categoria: el.category.name,
                    producto: el.taxonomy_product.product_name,
                    brand: el.taxonomy_product.brand,
                    feature_1: el.feature_1 && el.feature_1.name || '',
                    feature_2: el.feature_2 && el.feature_2.name || '',
                    feature_3: el.feature_3 && el.feature_3.name || '',
                    feature_4: el.feature_4 && el.feature_4.name || '',
                    feature_5: el.feature_5 && el.feature_5.name || '',
                    md5_group: el.md5_group,
                    fecha: el.fecha,
                    enlace: el.enlace,
                }));

                excelService.writeXLSFile(fileName, sheetName, columns, dataKeys, productsData);
                this.isExporting = false;
            } catch (e) {
                this.isExporting = false;
                console.log('=== Error ===', e);
                this.$store.dispatch("alert_error", {
                    message: "No fue posible exportar a los datos",
                    time: this.time,
                });
            }
        },
    },
};
</script>
<style lang="css"></style>
