<template>
    <div>
        <div v-if="Areas.length > 0">
            <DataTable ref="dt" :value="Areas" dataKey="id" :class="
                ' editable-cells-table datatable-custom datatable-custom-edit mt-3 pl-2 ' +
                collapse
            " editMode="cell" :rowClass="rowClass" :filters="filters">
                <template #header>
                    <div class="table-header" style="display: flex">
                        <h4 class="p-m-0" style="vertical-align: middle; align-self: center"></h4>
                        <span class="p-input-icon-left" style="margin-left: auto">
                            <i class="pi pi-search" />

                            <AutoComplete v-model="search" class="custom-auto-complete not-icon" placeholder="Buscar..."
                                @complete="sortableSearch($event)" field="Nombre" />
                        </span>
                    </div>
                </template>

                <Column field="id" headerStyle="width: 100px;">
                    <template #header>
                        <span @click="sortableId" class="p-column-title">
                            ID
                        </span>
                    </template>
                </Column>

                <Column field="name" headerStyle="width: 12rem">
                    <template #header>
                        <span @click="sortableName" class="p-column-title">
                            Nombre
                        </span>
                    </template>

                    <template #body="slotProps">
                        <InputText v-model="slotProps.data[slotProps.column.field]"
                            class="custom-dropdown inputtext-custom pl-2" :placeholder="slotProps.data.placeHolder" />
                    </template>
                </Column>
                <Column>
                    <template #body="slotProps">
                        <div v-if="slotProps.data.type" style="text-align: right" class="p-mr-2">
                            <SplitButton label="Save" class="custom-split-button" :model="slotProps.data.buttons"
                                @click="postArea(slotProps.data.name)"></SplitButton>
                        </div>
                        <div v-else style="text-align: right" class="p-mr-2">
                            <Button label="Guardar modificación" style="width: 160px" class="button-custom mr-2"
                                @click="editPerId(slotProps.data)"></Button>
                            <Button label="Borrar" style="width: 160px" class="button-custom-danger"
                                @click="delAreaPerId(slotProps.data.id)"></Button>
                        </div>
                    </template>
                    <template #header>
                        <div style="
                                display: flex;
                                justify-content: end;
                                margin-right: 8px;
                            ">
                            <div style="
                                    display: flex;
                                    align-self: center;
                                    margin-right: 15px;
                                ">
                                <Button icon="pi pi-file-excel" class="
                                        p-button-rounded
                                        p-button-success
                                        p-button-outlined
                                        btn-green
                                    " @click="getTemplate"></Button>
                            </div>
                            <FileUpload name="demo" icon="pi pi-file-excel" class="custom-upload" mode="basic"
                                :accept="'.xls, .xlsx'" @select="errorUpload" :multiple="false" @error="errorUpload"
                                :chooseLabel="'Importar'" :customUpload="true" @uploader="customUpload"
                                :showUploadButton="true" ref="upload" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </div>
        <div v-if="Areas.length <= 0" class="container-custom" style="margin-top: 250px">
            <ProgressSpinner />
        </div>
        <div v-if="Areas.length > 0">
            <Paginator :total_pages="total_pages" :total_dados="total_dados" :page_init="current_page"
                @handler="dataHandler"></Paginator>
        </div>
    </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import { AreaService } from "../../services/AreaService";
import InputText from "primevue/inputtext";
import Rating from "primevue/rating";
import Toolbar from "primevue/toolbar";
import SplitButton from "primevue/splitbutton";
import Paginator from "./../../components/Paginator.vue";
import ProgressSpinner from "primevue/progressspinner";
import AutoComplete from "primevue/autocomplete";
import env from "./../../env";
import FileUpload from "primevue/fileupload";

import { excelService } from "./../../services/Excel/excelService.js";

import "primeflex/primeflex.css";
export default {
    components: {
        DataTable,
        Column,
        Button,
        Rating,
        InputText,
        Toolbar,
        FileUpload,
        SplitButton,
        Paginator,
        ProgressSpinner,
        AutoComplete,
        FileUpload,
    },
    data() {
        return {
            //Area
            Areas: [],
            selectedArea: null,
            filters: {},

            current_page: 1,

            //Default
            default: null,
            buttons: null,

            //Inputs
            inputPost: null,

            search: null,

            options: null,
            links: null,

            propsPaginator: null,

            collapse: "table-collapsed",
            spinner: "spinner-loading",

            sortable: "id",
            asc: -1,

            Linha: 0,

            total_pages: null,
            total_dados: null,
        };
    },
    created() {
        //Definindo os arrays para poder acessas methods pelos arrays
        this.default = {
            id: "ID",
            name: null,
            placeHolder: "Completa el nombre",
            type: "INCREMENT",
            buttons: [
                {
                    label: "Clear",
                    command: () => {
                        this.clearInsertPerline("ID");
                    },
                },
            ],
        };

        this.buttons = [
            {
                label: "Borrar",
                command: () => { },
            },
        ];

        //Renderiza os dados
        this.dataHandler();
    },

    watch: {
        search(newVal, oldVal) {
            if (newVal == "") {
                this.dataHandler();
            }
        },

        Areas(newVal, oldVal) {
            console.log(newVal);
            newVal.map((item) => {
                this.Linha += 1;
                if (item.type != "INCREMENT") {
                    if (this.Linha % 2 == 0) {
                        item.class = " linea_gris";
                    } else {
                        item.class = " linea_white";
                    }
                }
            });
        },
    },

    methods: {
        //Renderiza os dados
        dataHandler(currentPage, name) {
            if (localStorage.http_token == null) {
                localStorage.clear();
                this.$router.push({ path: "/login" });
            } else {
                this.current_page = currentPage ? currentPage : 1;
                console.log(this.currentPage)
                this.Areas = [];
                AreaService.getNamePerPage(
                    name,
                    this.sortable,
                    this.asc,
                    this.current_page
                )
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        if (result.request.status == 200) {
                            console.log(result.data);
                            this.Areas.push(this.default);
                            result.data.data.map((item) => {
                                this.total = result.data.total;
                                item.buttons = this.buttons;

                                this.Areas.push(item);
                            });

                            //Função que limpa os campos do insert per line
                            this.clearInsertPerline("ID");

                            this.total_pages = result.data.total_pages;

                            this.total_dados = result.data.total;

                            this.collapse = "not-collapsed"; //Css propriedades
                            this.spinner = "spinner-not"; //Css propriedades
                        } else if (result.request.status != 200) {
                            var message = result.response.data.message;
                            this.$store.dispatch("alert_error", {
                                message: message,
                                time: 3000,
                            });
                        } else {
                            var message = "Internal error";
                            this.$store.dispatch("alert_error", {
                                message: message,
                                time: 3000,
                            });
                        }
                    })
                    .catch((err) => {
                        var message = "Internal error";
                        this.$store.dispatch("alert_error", {
                            message: message,
                            time: 3000,
                        });
                    });
            }
        },

        //Clear primeira linha
        clearInsertPerline(id) {
            this.Areas.map((item) => {
                if (item.id == id) {
                    item.name = null;
                    return item;
                }
            });
        },

        //Post Area
        postArea(inputText) {
            if (inputText == null || inputText == "") {
                this.$store.dispatch("alert_error", {
                    message: "El nombre no se llenó",
                    time: 1500,
                });
            } else {
                AreaService.postArea(inputText)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        if (result.request.status == 200) {
                            this.dataHandler();

                            //Alert
                            const message = result.data.success;
                            const capitalized =
                                message[0].toUpperCase() + message.substr(1);

                            this.total++;

                            this.$store.dispatch("alert_success", {
                                message: capitalized,
                                time: 2000,
                            });
                        } else if (result.request.status != 200) {
                            var message = result.response.data.errors.name[0];
                            this.$store.dispatch("alert_error", {
                                message: message,
                                time: 3000,
                            });
                        } else {
                            var message = "Internal error";
                            this.$store.dispatch("alert_error", {
                                message: message,
                                time: 3000,
                            });
                        }
                    })
                    .catch((err) => {
                        var message = "Internal error";
                        this.$store.dispatch("alert_error", {
                            message: message,
                            time: 3000,
                        });
                    });
            }
        },

        //Delete area
        delAreaPerId(id) {
            AreaService.delAreaId(id)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    if (result.request.status == 200) {
                        this.$store.dispatch("alert_error", {
                            message: "Eliminado con éxito",
                            time: 1500,
                        });

                        this.total -= 1;

                        this.Areas = this.Areas.filter((area) => area.id != id);
                    } else if (result.request.status != 200) {
                        var error = result.response.data.errors.delete[0];
                        this.$store.dispatch("alert_error", {
                            message: error,
                            time: 3000,
                        });
                    } else {
                        var message = "Internal error";
                        this.$store.dispatch("alert_error", {
                            message: message,
                            time: 3000,
                        });
                    }
                })
                .catch((err) => {
                    var message = "Internal error";
                    this.$store.dispatch("alert_error", {
                        message: message,
                        time: 3000,
                    });
                });
        },

        editPerId(data) {
            var id = data.id;
            var name = data.name;

            AreaService.updateAreaId(id, name)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    if (result.request.status == 200) {
                        this.$store.dispatch("alert_success", {
                            message: "Actualizado exitosamente",
                            time: 2000,
                        });
                    } else if (result.request.status != 200) {
                        var message = result.response.data.errors.name[0];
                        this.$store.dispatch("alert_error", {
                            message: message,
                            time: 4000,
                        });
                    } else {
                        var message = "Internal error";
                        this.$store.dispatch("alert_error", {
                            message: message,
                            time: 3000,
                        });
                    }
                })
                .catch((err) => {
                    var message = "Internal error";
                    this.$store.dispatch("alert_error", {
                        message: message,
                        time: 3000,
                    });
                });
        },

        loadings(boolean) {
            if (boolean == true) {
                this.collapse = "table-collapsed"; //Css propriedades
                this.spinner = "spinner-loading"; //Css propriedades
            } else {
                setTimeout(() => {
                    this.collapse = "not-collapsed"; //Css propriedades
                    this.spinner = "spinner-not"; //Css propriedades
                }, 1500);
            }
        },

        sortableId() {
            this.sortable = "id";
            if (this.asc == 1) {
                this.asc = -1;
            } else {
                this.asc = 1;
            }

            this.dataHandler();
        },

        sortableName() {
            this.sortable = "name";
            if (this.asc == 1) {
                this.asc = -1;
            } else {
                this.asc = 1;
            }

            this.dataHandler();
        },

        sortableSearch(e) {
            this.dataHandler(null, null, e.query);
        },

        rowClass(data) {
            return data.class;
        },

        customUpload(event) {
            var files = event.files[0];
            excelService
                .postExcel("areas", files)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    this.dataHandler();
                    this.$refs.upload.clear();
                    this.$store.dispatch("alert_success", {
                        message: `Cargado correctamente`,
                        time: 3000,
                    });
                })
                .catch((err) => { });
        },

        errorUpload(event) {
            if (event) {
                if (event.files && event.files.length == 0) {
                    this.$store.dispatch("alert_error", {
                        message: `Solo aceptamos archivos con las extensiones .xls, .xlsx`,
                        time: 3000,
                    });
                }
            } else {
                this.$store.dispatch("alert_error", {
                    message: `Solo aceptamos archivos con las extensiones .xls, .xlsx`,
                    time: 3000,
                });
            }
        },

        getTemplate() {
            excelService.getExcel("areas");
        },
    },
};
</script>
