<template>
    <div>

        <transition name="fade">
            <div
                v-if="total_dados_export != total_dados && total_dados > 0 && excel == true"
                class="container_toast_carge"
            >
                <Notification :count="total_dados_export" :total="total_dados" :excel_arq="0" :excel_arq_total="10"/>
            </div>
        </transition>


        <div class="content-query">
            <!-- @_____ == function callback -->
            <Query
                type="pendiente"
                @getObtener="getObtener"
                :btnLeftLabel="'Obtener'"
                @exportExcel="Export"
                :disableObtener="(productService.length > 0 && isExporting) || loading"
                :disabledExport="productService.length > 0 && !isExporting"
            ></Query>
        </div>

        <!-- @_____ == function callback -->
        <div>
            <Table :values="productService" @taxonomizado="taxonomizadoFunc"></Table>
        </div>

        <div v-if="loading == true" class="container-custom">
            <ProgressSpinner />
        </div>
        <div v-if="productService.length > 0">
            <Paginator :total_pages="total_pages" :total_dados="total_dados" :page_init="current_page"
                @handler="getObtener" :props="true"></Paginator>
        </div>

        <span v-show="isExporting" class="exporting-notification" >Exportando datos ...</span>
    </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import Query from "@/components/Query/Query.vue";
import Table from "@/components/Tables/Table.vue";
import ProgressSpinner from "primevue/progressspinner";
import Notification from "@/components/NotificationDataHandler/Notification.vue";

//SERVICES
import { CountryService } from "@/services/Taxonomia/CountryService";
import { ProductsService } from "@/services/Taxonomia/ProductsService";
import excelService from "@/services/Excel/excelService";
import IndexDB from "@/services/IndexDBService";
import Paginator from "@/components/Paginator.vue";


export default {
    components: {
        Dropdown,
        MultiSelect,
        Button,
        Table,
        Query,
        ProgressSpinner,
        Paginator,
        Notification
    },
    data() {
        return {
            // DATA - API
            productService: [],
            loading: false,

            //Css propriedades
            widthTable: "auto",
            collapse: "table-collapsed",
            spinner: "spinner-not",

            //Alert
            time: 2000,

            total: 0,

            request: false,

            total_pages: 0,
            total_dados: 0,
            current_page: 1,
            props_request: null,

            current_page_excel: 1,

            body_export: {},

            total_dados_export: 0,

            excel_arq: 0,
            excel_arq_total: 0,
            excel: false,

            isExporting: false,
        };
    },

    created() {
        IndexDB.createDB(
            () => {},
            "pendientes",
            "DBTO",
            "category, product_name, last_seen, retail",
            ["category", "product_name", "last_seen", "retail"],
            false,
            { autoIncrement: true, keyPath: "md5_link" }
        );
    },

    methods: {

        createExcel(from, to) {
            const cols = [
                "Country",
                "Nombre",
                "Link",
                "Model",
                "Retail",
                "Categoria",
                "Brand",
                "md5 link",
                "Fecha",
            ];

            const props = [
                "country",
                "product_name",
                "link",
                "model",
                "retail",
                "category",
                "brand",
                "md5_link",
                "last_seen",
            ];

            const date = new Date()
            const dateFormat = new Date().toLocaleDateString('pt-BR').replaceAll("/", "-")
                

            IndexDB.exportExcel(
                dateFormat + " Pendientes" + " " + from + "-" + to,
                "pendientes",
                "DBTO",
                cols,
                props
            );
        },

        async incrementProductIndexedDB(products) {
            return new Promise((resolve) => {
                let total_dados_load = 0

                products.map((product) => {
                    IndexDB.itemADD(
                        product,
                        () => {
                            total_dados_load++

                            if (total_dados_load === products.length) {
                                resolve(total_dados_load)
                            }
                        },
                        "pendientes",
                        "DBTO"
                    );
                });
            });
        },

        hadlerRequestsExcel(total_dados, limitProducts, totalPageByLimit, total_pages) {
            return new Promise(async (resolve) => {
                
                let export_products = 0

                for (let i = 1; i <= totalPageByLimit; i++) {
                    const request = await ProductsService.getPendingParamPage(
                        this.current_page_excel,
                        this.body_export.country,
                        this.body_export.retail,
                        this.body_export.category,
                        this.body_export.brand,
                        this.body_export.start_date,
                        this.body_export.end_date,
                        this.body_export.name,
                        limitProducts
                    );

                    if (request.status === 200) {

                        const { data } = request.data
                        const {  to  } = request.data
                        const from = this.total_dados_export

                        export_products += await this.incrementProductIndexedDB(data)

                        if(export_products == total_dados) resolve({export_products, from, to})

                        if(this.current_page_excel == total_pages) resolve({export_products, from, to})

                        this.current_page_excel += 1
                        this.total_dados_export += data.length
                    }
                }
            })
        },

        async exportExcel() {

            const limitProducts = 500
            const limit_dados = this.total_dados >  1000 ?  1000 : this.total_dados
            const limit_dados_explode = this.total_dados > limit_dados
            const limit_excel = limit_dados_explode ? Math.ceil(this.total_dados / limit_dados) : 1

            let total_pages = 0

            this.current_page_excel = 1
            this.excel_arq = 0
            this.excel_arq_total = limit_excel
            this.excel = true

            const requestCount = await ProductsService.getPendingCount(
                this.body_export.country,
                this.body_export.retail,
                this.body_export.category,
                this.body_export.brand,
                this.body_export.start_date,
                this.body_export.end_date,
                this.body_export.name,
                limitProducts
            );

            if (!requestCount.status) return

            total_pages = requestCount.data.total_pages
            
            for (let i = 1; i <= limit_excel; i++) {
                IndexDB.clearStore("pendientes", "DBTO")
                this.excel_arq += 1

                const rest = this.total_dados % limit_dados == 0 ? limit_dados : this.total_dados % limit_dados

                let totalLimitProducts = i == limit_excel ? rest : limit_dados
                let totalPageByLimit = Math.ceil(totalLimitProducts / limitProducts)

                console.log(totalPageByLimit, totalLimitProducts)

                const hadlerRequestsExcel = await this.hadlerRequestsExcel(limit_dados, limitProducts, totalPageByLimit, total_pages)
                
                this.createExcel(hadlerRequestsExcel.from, hadlerRequestsExcel.to)
            }

            this.excel = false
        },

        //TABLE
        getObtener(value, page) {
            this.productService = [];
            var page;

            if (!value) {
                value = this.props_request;
            } else {
                this.props_request = value;
            }

            page = page ? page : 1;

            console.log(value, this.props_request, page);

            if (!value.country) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione el país primero",
                    time: this.time, //Padrao 2000,
                });
            } else if (!value.retail) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione las retail primero",
                    time: this.time, //Padrao 2000,
                });
            } else if (value.category || value.category_abm) {
                this.loading = true;

                var request_body = {};

                request_body.country = value.country;

                //Tratando as variaveis para o request
                request_body.category = value.category.map((item) => {
                    return item.category;
                });

                if (value.brand) {
                    request_body.brand = value.brand.map((item) => {
                        return item.brand;
                    });
                } else {
                    request_body.brand = [];
                }

                request_body.retail = value.retail.map((item) => {
                    return item.retail;
                });

                request_body.start_date = value.start_date;
                request_body.end_date = value.end_date;
                request_body.name = value.name;

                this.body_export = request_body;

                ProductsService.getPendingCount(
                    request_body.country,
                    request_body.retail,
                    request_body.category,
                    request_body.brand,
                    request_body.start_date,
                    request_body.end_date,
                    request_body.name
                )
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }

                        this.current_page = page ? page : 1;
                        console.log(page);
                        this.total_pages = result.data.total_pages;
                        this.total_dados = result.data.total;

                        if (result.total_dados <= 0) {
                            this.$store.dispatch("alert_error", {
                                message: "result.message",
                                time: 3000,
                            });

                            return;
                        }

                        ProductsService.getPendingParamPage(
                            page,
                            request_body.country,
                            request_body.retail,
                            request_body.category,
                            request_body.brand,
                            request_body.start_date,
                            request_body.end_date,
                            request_body.name
                        ) //Request
                            .then((result) => {
                                const resultValidation = this.$root.resultValidationRequest(result);

                                if (resultValidation != true) {
                                    this.$store.dispatch("alert_error", {
                                        message: resultValidation,
                                        time: this.time,
                                    });

                                    return;
                                }

                                var id = 0;
                                result.data.data.map((item) => {
                                    id++;

                                    item.brand_abm = [];
                                    item.nombre = [];
                                    item.categoria_abm = [];

                                    item.id_ = id;

                                    item.nombre_status = false;

                                    item.class = "";

                                    item.feature_1 = [];
                                    item.feature_2 = [];
                                    item.feature_3 = [];
                                    item.feature_4 = [];
                                    item.feature_5 = [];

                                    item.options = {
                                        brand_abm: {
                                            suggestions: [],
                                            disabled: false,
                                        },
                                        name: {
                                            suggestions: [],
                                            disabled: true,
                                        },
                                        categoria_abm: {
                                            suggestions: [],
                                            disabled: true,
                                        },
                                        btn_agregar: { disabled: true },
                                        features: {
                                            disabled: true,
                                            feature_1: [],
                                            feature_2: [],
                                            feature_3: [],
                                            feature_4: [],
                                            feature_5: [],
                                        },
                                    };

                                    item.buttons = [
                                        {
                                            label: "Descartar",
                                            id: item.id_,
                                            md5: item.md5_link,
                                            command: (click) => {
                                                this.softDelete(click.item.md5, item.id_);
                                            },
                                        },
                                        {
                                            label: "Cancelar",
                                            id: id,
                                            command: (click) => {
                                                this.cancelar(click.item.id);
                                            },
                                        },
                                    ];

                                    this.productService.push(item); //Passando para props
                                });

                                this.loading = false;
                            })
                            .catch((err) => { });
                    })
                    .catch((err) => { });
            } else {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione las categorías primero",
                    time: this.time, //Padrao 2000
                });
            }
        },

        //BUTTONS
        softDelete(md5, id) {
            this.productService.map((item) => {
                if (item.md5_link == md5) {
                    item.class = "removed";
                }
            });

            ProductsService.softDelete(md5)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }

                    this.$store.dispatch("alert_success", {
                        //Alert
                        message: "Producto desechado",
                        time: 3000,
                    });

                    this.total -= 1;

                    this.productService = this.productService.filter(
                        (item) => item.md5_link != md5
                    );

                    var id_ = 0;

                    this.productService.map((item) => {
                        id_++;
                        item.id_ = id_;
                    });

                    this.$children[1].$refs["brand_" + id][0].focus();
                })
                .catch((err) => { });
        },

        cancelar(click) {
            this.productService.map((item) => {
                //Limpa todos os campos de uma coluna que efetuou o click
                if (item.id == click) {
                    item.nombre = null;
                    item.area_abm = [];
                    item.division_abm = [];
                    item.categoria_abm = [];
                    item.feature_1_abm = [];
                    item.feature_2_abm = [];
                    item.feature_3_abm = [];
                    item.feature_4_abm = [];
                    item.feature_5_abm = [];
                    item.brand_abm = [];
                    item.options = {
                        name: { disabled: true },
                        area_abm: { disabled: true },
                        division_abm: { values: [], disabled: true },
                        categoria_abm: { values: [], disabled: true },
                        features: {
                            disabled: true,
                            feature_1_abm: { values: [] },
                            feature_2_abm: { values: [] },
                            feature_3_abm: { values: [] },
                            feature_4_abm: { values: [] },
                            feature_5_abm: { values: [] },
                        },
                        brand_abm: { disabled: false },
                    };
                }
            });
        },

        loadings(boolean) {
            if (boolean == true) {
                this.collapse = "table-collapsed"; //Css propriedades
                this.spinner = "spinner-loading"; //Css propriedades
            } else {
                setTimeout(() => {
                    this.collapse = "not-collapsed"; //Css propriedades
                    this.spinner = "spinner-not"; //Css propriedades
                }, 1500);
            }
        },

        taxonomizadoFunc(data) {
            this.total -= 1;
            this.productService = data;

            var id_ = 0;

            this.productService.map((item) => {
                id_++;
                item.id_ = id_;
            });
        },

        async Export() {
            const maxDataAmount = 60000;
            const fileName = 'Historico de precios';
            const sheetName = 'historico';
            const columns = [
                "Country",
                "Nombre",
                "Link",
                "Model",
                "Retail",
                "Categoria",
                "Brand",
                "md5 link",
                "Fecha",
            ];
            const dataKeys = [
                "country",
                "product_name",
                "link",
                "model",
                "retail",
                "category",
                "brand",
                "md5_link",
                "last_seen",
            ];
            const {
                country, retail, category, brand, start_date, end_date, name,
            } = this.body_export;

            try {
                this.isExporting = true;
                const queryLimit = this.total_dados < maxDataAmount
                    ? this.total_dados
                    : maxDataAmount;

                const response = await ProductsService.getPendingParamPage(
                    1,
                    country,
                    retail,
                    category,
                    brand,
                    start_date,
                    end_date,
                    name,
                    queryLimit,
                );
                const productsData = response.data.data;

                excelService.writeXLSFile(fileName, sheetName, columns, dataKeys, productsData);
                this.isExporting = false;
            } catch (e) {
                this.isExporting = false;
                console.log('=== Error ===', e);
                this.$store.dispatch("alert_error", {
                    message: "No fue posible exportar a los datos",
                    time: this.time,
                });
            }
        },
    },
};
</script>
<style>
.exporting-notification {
    background-color: #2196F3;
    padding: 0.5rem;
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #fff;
}
</style>
