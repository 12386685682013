<template>
    <div>
        <div class="content-query">
            <!-- @_____ == function callback -->
            <Query type="descartado" @getObtener="getObtener" :btnLeftLabel="'Obtener'" :width="'255px'"></Query>
        </div>

        <!-- @_____ == function callback -->
        <div>
            <Table :values="productService" :collapse="collapse" :descartar="true" @retroceder="retrocederFunc"></Table>
        </div>

        <div class="container-custom" v-if="loading == true">
            <ProgressSpinner />
        </div>

        <div v-if="productService.length > 0">
            <Paginator :total_pages="total_pages" :total_dados="total_dados" :page_init="current_page"
                @handler="getObtener" :props="true"></Paginator>
        </div>
    </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import Query from "../../components/Query/Query.vue";
import Table from "../../components/Tables/Table.vue";
import ProgressSpinner from "primevue/progressspinner";

//SERVICES
import { ProductsService } from "../../services/Taxonomia/ProductsService";

import Paginator from "../../components/Paginator.vue";

export default {
    components: {
        Dropdown,
        MultiSelect,
        Button,
        Table,
        Query,
        ProgressSpinner,
        Paginator,
    },
    data() {
        return {
            // DATA - API
            productService: [],
            loading: false,

            //CHIPS
            //Pais
            paisList: [],
            paisSelect: null,
            paisSelectColumn: null,

            paisQuery: null,

            //Retail
            ChipsRetail: [],

            selectedRetail: null,

            //Categoria
            ChipsCategoria: [],
            List: [],

            selectedCategoria: null,

            options: null,
            links: null,

            props: null,
            link: null,

            //Css propriedades
            widthTable: "auto",
            collapse: "table-collapsed",
            spinner: "spinner-not",

            request: false,

            total_dados: 0,
            total_pages: 0,
            current_page: 1,
        };
    },

    methods: {
        clearList() {
            this.paisSelect = null; //Clear all values
            this.ChipsRetail = [];
            this.ChipsCategoria = [];
            this.ChipsBrand = [];
            this.paisSelect = null;
            this.selectedRetail = null;
            this.selectedCategoria = null;
            this.selectedBrand = null;

            this.productService = [];
        },

        getObtener(value, page) {
            this.productService = []; //Clear values

            console.log(value)

            if (!value) {
                value = this.props_request;
                console.log(value)
            } else {
                this.props_request = value;
            }

            console.log(value, this.props_request)

            page = page ? page : 1;

            if (!value.country) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione el país primero",
                    time: 2000,
                });
            } else if (!value.retail) {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione las retail primero",
                    time: 2000,
                });
            } else if (value.category || value.category_abm) {
                this.loading = true; //Css propriedades

                var request_props = value;

                request_props.category = request_props.category.map((item) => {
                    return item.category ? item.category : item;
                }); //Ajustando as variaveis
                request_props.retail = request_props.retail.map((item) => {
                    return item.retail ? item.retail : item;
                }); //Ajustando as variaveis

                if (request_props.brand != null) {
                    request_props.brand = request_props.brand.map((item) => {
                        return item.brand ? item.brand : item;
                    })
                }

                request_props.start_date = value.start_date;
                request_props.end_date = value.end_date;
                request_props.name = value.name;

                ProductsService.getDescartadosCount(
                    request_props.country,
                    request_props.retail,
                    request_props.category,
                    request_props.brand,
                    request_props.start_date,
                    request_props.end_date,
                    request_props.name
                )
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }

                        this.total_dados = result.data.total;
                        this.total_pages = result.data.total_pages;

                        ProductsService.getDescartadosParams(
                            value.country,
                            request_props.retail,
                            request_props.category,
                            page,
                            request_props.brand,
                            request_props.start_date,
                            request_props.end_date,
                            request_props.name
                        ) //Request, e montando o objeto para passar para props
                            .then((result) => {
                                const resultValidation = this.$root.resultValidationRequest(result);

                                if (resultValidation != true) {
                                    this.$store.dispatch("alert_error", {
                                        message: resultValidation,
                                        time: this.time,
                                    });

                                    return;
                                }

                                this.current_page = page ? page : result.data.current_page;
                                this.productService = result.data.data;

                                this.loading = false;
                            })
                            .catch((err) => { });
                    })
                    .catch((err) => { });
            } else {
                this.$store.dispatch("alert_error", {
                    //Alert
                    message: "Seleccione las categorías primero",
                    time: 2000,
                });
            }
        },

        //BUTTONS

        cancelar(click) {
            this.productService.map((item) => {
                if (item.id == click) {
                    //Limpa todos os campos de uma coluna que efetuou o click
                    item.nombre = null;
                    item.area_abm = [];
                    item.division_abm = [];
                    item.categoria_abm = [];
                    item.feature_1_abm = [];
                    item.feature_2_abm = [];
                    item.feature_3_abm = [];
                    item.feature_4_abm = [];
                    item.feature_5_abm = [];
                    item.brand_abm = [];
                    item.options.division_abm.disabled = true;
                    item.options.categoria_abm.disabled = true;
                    item.options.features.disabled = true;
                }
            });
        },

        loadings(boolean) {
            if (boolean == true) {
                this.collapse = "table-collapsed"; //Css propriedades
                this.spinner = "spinner-loading"; //Css propriedades
            } else {
                setTimeout(() => {
                    this.collapse = "not-collapsed"; //Css propriedades
                    this.spinner = "spinner-not"; //Css propriedades
                }, 1500);
            }
        },

        retrocederFunc() {
            this.total -= 1;
        },
    },
};
</script>
<style lang=""></style>
