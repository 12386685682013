<template>
    <div class="p-mt-4 content-query">
        <div style="display: flex" class="mb-2">
            <Dropdown class="custom-input-max-min custom-dropdown filter p-mr-2" v-model="selectedPais" :options="pais"
                @input="inputPais(selectedPais)" @hide="hidePais" @change="guard()" :filter="true" optionLabel="name"
                placeholder="País" :disabled="disabledOpt.pais" />
            <MultiSelect v-model="selectedRetail" class="custom-input-max-min-300 custom-multiselect filter p-mr-2"
                :options="retail" :filter="true" optionLabel="retail" placeholder="Retail" display="chip"
                :disabled="disabledOpt.retail" />
            <Button
                class="custom-input-max-min button-custom mr-2 custom-button-max-min"
                label="Obtener"
                :disabled="disabledObtener"
                @click="getQuery"
            ></Button>
            <Button
                class="custom-input-max-min button-custom-green mr-2 custom-button-max-min"
                label="Export"
                :disabled="!disabledExport"
                @click="exportExcel"
            ></Button>
            <Button
                class="button-custom-limpiar custom-button-max-min"
                label="LIMPIAR"
                v-on:click="clearCampos"
            ></Button>
        </div>
        <div>
            <AutoComplete v-model="selectedArea" :suggestions="area" :disabled="disabledOpt.area"
                @complete="searchArea($event)" @item-select="selectArea($event)" field="name" placeholder="Area" class="custom-input-max-min 
                    custom-multiselect custom-auto-complete
                    disabled
                    auto-complete-taxonomizado
                    no-border
                " :dropdown="true" />

            <AutoComplete v-model="selectedDivision" :suggestions="division" :disabled="disabledOpt.division"
                @complete="searchDivision($event)" @item-select="selectDivision($event)" field="name"
                placeholder="Division"
                class="custom-input-max-min custom-multiselect custom-auto-complete disabled auto-complete-taxonomizado no-border p-ml-2"
                :dropdown="true" />

            <MultiSelect v-model="selectedCategoria" class="custom-input-max-min-300 custom-multiselect filter p-ml-2"
                :options="categoria" :filter="true" optionLabel="name" placeholder="Categoria" display="chip"
                @filter="searchCategoria($event)" @show="showCategoria" :disabled="disabledOpt.categoria" />

            <MultiSelect v-model="selectedBrand" class="custom-input-max-min-300 custom-multiselect filter p-ml-2"
                :options="brand" :filter="true" optionLabel="name" placeholder="Brand" display="chip"
                @filter="searchBrand($event)" :disabled="disabledOpt.brand" />

            <AutoComplete
                v-model="nombre_query"
                :suggestions="suggestions.nombre"
                field="nameDescription"
                class="custom-auto-complete max-width ml-2 custom-input-max-min-300"
                placeholder="Nombre producto"
                @complete="searchNombre"
                :dropdown="true"
                :disabled="!selectedBrand || selectedBrand.length !== 1 || selectedCategoria.length !== 1" />
        </div>
    </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import { BrandService } from "../../services/BrandService";
import { AreaService } from "../../services/AreaService";
import { DivisionService } from "../../services/DivisionService";
import { CategoriaServiceABM } from "../../services/CategoriaService";
import { CountryService } from '../../services/Taxonomia/CountryService';
import { RetailsService } from '../../services/Taxonomia/RetailsService';
import { ProductsService } from "@/services/Taxonomia/ProductsService.js";

export default {
    components: {
        Dropdown,
        MultiSelect,
        Button,
        AutoComplete,
    },

    data() {
        return {
            selectedDivision: null,
            selectedArea: null,
            selectedBrand: null,
            selectedCategoria: null,
            selectedPais: null,
            selectedRetail: null,

            disabledOpt: {
                pais: false,
                retail: true,
                area: false,
                division: true,
                categoria: true,
                brand: false,
            },

            pais: [], //ENTORNO ANTIGO
            retail: [], //ENTORNO ANTIGO

            area: [], //ABM
            division: [], //ABM
            categoria: [], //ABM
            brand: [], //ABM

            nombre_query: null,
            suggestions: {
                brand: null,
                nombre: null,
            },
        };
    },

    created() {
        CountryService.getAll().then((result) => {
            const resultValidation = this.$root.resultValidationRequest(result);

            if (resultValidation != true) {
                this.$store.dispatch("alert_error", {
                    message: resultValidation,
                    time: this.time,
                });

                return;
            }
            this.pais = result.data;
        }).catch((err) => {

        });

        BrandService.getAll().then((result) => {
            const resultValidation = this.$root.resultValidationRequest(result);

            if (resultValidation != true) {
                this.$store.dispatch("alert_error", {
                    message: resultValidation,
                    time: this.time,
                });

                return;
            }
            console.log(result)
            result.data.data = result.data.data.map((item) => {
                item.name = item.name.toLowerCase();
                return item;
            });
            this.brand = result.data.data;
        })
    },

    props: [
        "disabledExport",
        "disabledObtener",
    ],

    methods: {
        exportExcel() {
            this.$emit("exportExcel")
        },

        hidePais() {
            if (this.selectedPais == null) {
                this.selectedRetail = null;
                this.retail = [];
                this.brand = [];
            } else {
                this.disabledOpt.retail = false;
            }
        },

        inputPais() {
            if (this.selectedPais) {
                RetailsService.getPerCountry(this.selectedPais.country).then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    this.retail = result.data;
                }).catch((err) => {

                });
            }
        },

        searchArea(event) {
            var name = event.query;

            this.disabledOpt.division = true;
            this.disabledOpt.categoria = true;

            this.selectedDivision = [];
            this.selectedCategoria = [];

            AreaService.getAutocomplete(name)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    result.data = result.data.map((item) => {
                        item.name = item.name.toLowerCase();
                        return item;
                    });
                    this.area = result.data;
                })
                .catch((err) => { });
        },

        selectArea(event) {
            this.disabledOpt.division = false;
        },

        searchDivision(event) {
            var name = event.query;
            var area_id = this.selectedArea.id;

            this.disabledOpt.categoria = true;

            this.selectedCategoria = [];

            console.log(this.area);

            if (area_id != null) {
                DivisionService.getAutocomplete(name, area_id)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        result.data = result.data.map((item) => {
                            item.name = item.name.toLowerCase();
                            return item;
                        });
                        this.division = result.data;
                    })
                    .catch((err) => { });
            }
        },

        selectDivision(event) {
            this.disabledOpt.categoria = false;
        },

        searchCategoria(event) {
            var name = event.value;
            var division_id = this.selectedDivision.id;

            if (division_id != null) {
                CategoriaServiceABM.getAutocomplete(name, division_id)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        this.categoria = result.data;

                        this.selectedCategoria.map((item) => {
                            var check = false;
                            this.categoria.map((categoria) => {
                                if (item.name == categoria.name) {
                                    check = true;
                                }
                            });

                            if (check == false) {
                                this.categoria.push(item);
                            }
                        });
                    })
                    .catch((err) => { });
            }
        },

        showCategoria() {
            var name = "";
            var division_id = this.selectedDivision.id;

            if (division_id != null) {
                CategoriaServiceABM.getAutocomplete(name, division_id)
                    .then((result) => {
                        const resultValidation = this.$root.resultValidationRequest(result);

                        if (resultValidation != true) {
                            this.$store.dispatch("alert_error", {
                                message: resultValidation,
                                time: this.time,
                            });

                            return;
                        }
                        result.data = result.data.map((item) => {
                            item.name = item.name.toLowerCase();
                            return item;
                        });
                        this.categoria = result.data;
                    })
                    .catch((err) => { });
            }
        },

        searchBrand(event) {
            var name = event.value;

            BrandService.getAutocomplete(name)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    result.data = result.data.map((item) => {
                        item.name = item.name.toLowerCase();
                        return item;
                    });
                    this.brand = result.data;

                    this.selectedBrand.map((item) => {
                        var check = false;
                        console.log(item)
                        this.brand.map((brand) => {
                            if (item.name == brand.name) {
                                check = true;
                            }
                        });

                        if (check == false) {
                            this.brand.push(item);
                        }
                    });
                })
                .catch((err) => { });
        },

        getQuery() {
            var query = [];

            //Country
            if (this.selectedPais != null) {
                query.country = this.selectedPais.country;
            }
            //Retail
            if (this.selectedRetail != null) {
                query.retail = this.selectedRetail;
            }
            //Category
            if (this.selectedCategoria != null) {
                query.category = this.selectedCategoria;
            }
            //Brand ou category_abm
            if (this.selectedBrand != null) {
                query.brand = this.selectedBrand;

                if(this.nombre_query && this.selectedBrand.length == 1) {
                    query.name = this.nombre_query.name
                        ? this.nombre_query.name.trim()
                        : this.nombre_query.trim();
                }
            }
            if (this.selectedArea != null) {
                query.area = this.selectedArea;
            }
            if (this.selectedDivision != null) {
                query.division = this.selectedDivision;
            }

            this.$emit("getObtener", query);
        },

        guard() {
            this.selectedRetail = null;
        },

        clearCampos() {
            this.selectedCategoria = null;
            this.selectedRetail = null;
            this.selectedBrand = null;
            this.selectedPais = null;
            this.selectedArea = null;
            this.selectedDivision = null;
            this.nombre_query = null;

            this.disabledOpt = {
                pais: false,
                retail: true,
                area: false,
                division: true,
                categoria: true,
                brand: false,
            };

            if (this.chipsRetail) {
                this.retail = [];
            }

            if (this.chipsArea) {
                this.area = [];
            }

            if (this.chipsDivision) {
                this.division = [];
            }

            if (this.chipsCategoria) {
                this.categoria = [];
            }

            if (this.chipsBrand) {
                this.brand = [];
            }
        },

        searchNombre(event) {
            const name = event.query;
            const brand_id = this.selectedBrand[0].id;
            const category_id = this.selectedCategoria[0].id;

            ProductsService.getNames(name, brand_id, category_id)
                .then((result) => {
                    const resultValidation = this.$root.resultValidationRequest(result);

                    if (resultValidation != true) {
                        this.$store.dispatch("alert_error", {
                            message: resultValidation,
                            time: 5000,
                        });

                        return;
                    }
                    result.data = result.data.map((item) => {
                        const { name, feature_2, feature_5 } = item;
                        const nameDescription = `${name}${feature_2 ? ' - '+feature_2.name : ''}${feature_5 ? ' - '+feature_5.name : ''}`;
       
                        item.name = item.name.toLowerCase();
                        item.nameDescription = nameDescription;
                        return item;
                    });
                    this.suggestions.nombre = result.data;
                })
                .catch((err) => { });
        },
    },
};
</script>
<style lang="">
</style>